import React from "react";
import "./Features.css";
import "../../index.css";
import { motion } from "framer-motion";

function Features() {
  function Panel({ title, summary, code, link, img }) {
    return (
      <motion.div
        className="folio__certificate-entry p-5 rounded-md max-w-[400px] relative"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
      >
        <img src={img} alt={title} className={"max-h-[250px] mx-auto"} />
        <p className="font-serif font-bold text-xl mt-5 my-auto">{title}</p>
        {summary ? <p className="mt-3 text-md">{summary}</p> : <></>}
        <div className="flex mt-6 space-x-5 w-full justify-center mx-auto">
          {link ? (
            <motion.a
              className="outline outline-1 outline-gray-600 flex w-1/3 py-2 justify-center align-middle"
              whileHover={{ backgroundColor: "#2e2e2e93" }}
              href={link}
              target={"_blank"}
            >
              <p className="text-white my-auto font-bold text-lg">Site</p>
            </motion.a>
          ) : (
            <></>
          )}
          {code ? (
            <motion.a
              className="outline outline-1 outline-gray-600 flex w-1/3 py-2 justify-center align-middle"
              whileHover={{ backgroundColor: "#2e2e2e93" }}
              href={code}
              target={"_blank"}
            >
              <p className="text-white my-auto font-bold text-lg">Code</p>
            </motion.a>
          ) : (
            <></>
          )}
        </div>
      </motion.div>
    );
  }

  function Title({ text }) {
    return (
      <div className="flex font-serif font-bold p-4 lg:text-2xl text-2xl text-center mx-auto flex-col">
        <p className="mx-auto">{text}</p>
        <div className="w-1/12 bg-white h-[2px] mt-3 mx-auto" />
      </div>
    );
  }

  function PanelHolder({ title, content }) {
    return (
      <div className="folio__Features">
        <div className="folio__Features_Body " id="projects">
          <div className="folio__Features_Body_Text text-white">
            <Title text={title} />
            <div className="flex flex-row flex-wrap">
              {content ? content : <></>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function SectionOne() {
    return (
      <>
        <Panel
          title="Token Swap Dex"
          summary="Token swap dex interface to trade erc20 tokens on ETH Mainnet using 1inch Aggregator."
          code="https://github.com/MaheshMannava/TokenSwap-Dex"
          link="https://transcendent-moonbeam-22491e.netlify.app/"
          img="/images/tokeninterface.gif"
        />
        <Panel
          title="Staking Farm"
          summary="A Full stack Defi staking application to stake your assets and earn yield for various locking periods. "
          code="https://github.com/MaheshMannava/staking-farm"
          link="https://elegant-rugelach-07ca44.netlify.app/"
          img="/images/stakingfarm.gif"
        />
        <Panel
          title="Etherscan clone"
          summary="Ethereum Block explorer built using with Nodejs, Express, Nextjs"
          code="https://github.com/MaheshMannava/etherscan-clone"
          link="https://visionary-medovik-f257cb.netlify.app/"
          img="/images/etherscanupdt.gif"
        />
        <Panel
          title="Decentralised Paypal"
          summary="A web3 version of Paypal to request and send payments in crypto along with previous transactions history dashboard."
          code="https://github.com/MaheshMannava/Decentralised-Paypal"
          link="https://lively-squirrel-c7803d.netlify.app/"
          img="/images/paypal.gif"
        />
        <Panel
          title="NFT Minting Application"
          summary="A simple nft minting contract with a UI."
          code="https://github.com/MaheshMannava/nft-minter-website"
          link="https://nft-minter-website.vercel.app/"
          img="/images/simplemint.gif"
        />
      </>
    );
  }

  function SectionTwo() {
    return (
      <>
        <Panel
          title="Bachelors in Computer Science Engineering"
          img="/images/vit.webp"
        />
      </>
    );
  }

  return (
    <>
      <PanelHolder title="My Projects" content={<SectionOne />} />
      <PanelHolder title="Education" content={<SectionTwo />}></PanelHolder>
    </>
  );
}

export default Features;
