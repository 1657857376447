import React from "react";

import { Footer } from "./containers";
import { Navbar } from "./components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home.tsx";
import Blog from "./pages/blog/Blog.tsx";
import { Analytics } from '@vercel/analytics/react';

import "./App.css";

const App = () => {
  return (
    <div className="App gradient__bg">
      <div className="subclass" >
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
          </Routes>
          <Footer />
        </Router>
        <Analytics />
      </div>
    </div>
  );
};

export default App;
